import React, { useState, useRef } from "react";
import {
  Button,
  TextField,
  Container,
  Typography,
  Box,
  Alert,
  Snackbar,
} from "@mui/material";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { scheduleCampaign, cancelCampaign } from "../services/api";
import { useParams } from "react-router-dom";

const ScheduleCampaign = () => {
  const { id } = useParams(); // Capturar el id de la URL
  const [selectedDate, setSelectedDate] = useState(null);
  const [intervalHours, setIntervalHours] = useState(""); // Intervalo en horas
  const [loading, setLoading] = useState(false);
  const datePickerRef = useRef(null); // Usamos un ref para manejar el DatePicker
  const [open, setOpen] = useState(false);
  const [message, setMessage] = useState("");
  const [severity, setSeverity] = useState("success"); // 'success' o 'error'

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!selectedDate) {
      setMessage("You have not selected a date.");
      setSeverity("error");
      setOpen(true);
      return;
    }

    setLoading(true);
    try {
      const campaignId = id;
      const scheduledTime = selectedDate ? selectedDate.toISOString() : null;
      const interval = intervalHours ? parseInt(intervalHours, 10) : null;

      // Llama a scheduleCampaign con los tres parámetros
      const response = await scheduleCampaign(
        campaignId,
        scheduledTime,
        interval
      );
      console.log("Lead capturado:", response);
      // Muestra el Snackbar de éxito
      setMessage("Scheduled Task.");
      setSeverity("success");
      setOpen(true);
    } catch (error) {
      console.error("Error programando la campaña:", error);
      // Muestra el Snackbar de error
      setMessage("There was a problem scheduling the task.");
      setSeverity("error");
      setOpen(true);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Container maxWidth="sm">
      <Typography variant="h4" gutterBottom>
        Schedule Campaign
      </Typography>
      <Box component="form" onSubmit={handleSubmit} sx={{ mt: 2 }}>
        <TextField
          label="Select date and time"
          fullWidth
          margin="normal"
          value={selectedDate ? selectedDate.toLocaleString() : ""}
          onClick={() => datePickerRef.current.setOpen(true)} // Abrir el DatePicker al hacer clic
          InputProps={{
            readOnly: true,
          }}
        />
        <DatePicker
          selected={selectedDate}
          onChange={(date) => setSelectedDate(date)}
          showTimeSelect
          dateFormat="Pp"
          customInput={<div />} // Oculta el campo del DatePicker
          ref={datePickerRef} // Conectar el DatePicker con el ref para abrirlo
        />
        {/* Campo para ingresar intervalo en horas */}
        <TextField
          label="Interval (hours)"
          type="number"
          fullWidth
          margin="normal"
          value={intervalHours}
          onChange={(e) => setIntervalHours(e.target.value)}
          placeholder="Enter interval in hours (optional)"
        />
        <Box display="flex" gap={2} mt={2}>
          <Button
            type="submit"
            variant="contained"
            color="primary"
            disabled={loading}
            sx={{ mt: 2 }}
          >
            {loading ? "Programming..." : "Schedule Campaign"}
          </Button>
          <Button
            variant="outlined"
            color="secondary"
            onClick={async () => {
              try {
                await cancelCampaign(id); // Llama a un endpoint que ejecute remove_campaign_task en el backend
                setMessage("Campaign canceled.");
                setSeverity("success");
                setOpen(true);
              } catch (error) {
                setMessage("There was a problem canceling the campaign.");
                setSeverity("error");
                setOpen(true);
              }
            }}
            sx={{ mt: 2 }}
          >
            Cancel Campaign
          </Button>
        </Box>
      </Box>
      <Snackbar
        open={open}
        autoHideDuration={6000}
        onClose={() => setOpen(false)}
      >
        <Alert
          onClose={() => setOpen(false)}
          severity={severity}
          sx={{ width: "100%" }}
        >
          {message}
        </Alert>
      </Snackbar>
    </Container>
  );
};

export default ScheduleCampaign;
