import React from "react";
import "./App.css";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Login from "./components/Login";
import LeadList from "./components/LeadList";
import LeadCapture from "./components/LeadCapture";
import CampaignList from "./components/CampaignList";
import CampaignCreate from "./components/CampaignCreate";
import ScheduleCampaign from "./components/ScheduleCampaign";
import Dashboard from "./components/Dashboard";
import HealthChatbot from "./components/Chatbot";
import Navbar from "./components/Navbar";

function App() {
  return (
    <Router>
      <div>
        {/* Incluimos el Navbar aquí para que esté visible en todas las rutas */}

        <Routes>
          <Route exact path="/" element={<Login />} />
          <Route
            path="/dashboard"
            element={
              <>
                <Navbar />
                <Dashboard />
              </>
            }
          />
          <Route
            path="/leads"
            element={
              <>
                <Navbar />
                <LeadList />
              </>
            }
          />
          <Route
            path="/campaigns"
            element={
              <>
                <Navbar />
                <CampaignList />
              </>
            }
          />
          <Route
            path="/campaign-create"
            element={
              <>
                <Navbar />
                <CampaignCreate />
              </>
            }
          />
          <Route
            path="/campaign-schedule/:id"
            element={
              <>
                <Navbar />
                <ScheduleCampaign />
              </>
            }
          />
          {/* Ruta sin Navbar */}
          <Route path="/leads-create" element={<LeadCapture />} />
          <Route path="/chatbot" element={<HealthChatbot />} />
        </Routes>
      </div>
    </Router>
  );
}

export default App;
