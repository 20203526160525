import React, { useEffect, useState } from "react";
import { getLeads } from "../services/api";
import {
  Container,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Button,
} from "@mui/material";
import { useNavigate } from "react-router-dom"; // Para navegación

function LeadList() {
  const [leads, setLeads] = useState([]);
  const navigate = useNavigate(); // Hook para navegar entre rutas

  useEffect(() => {
    getLeads()
      .then((response) => {
        setLeads(response.data); // Asigna los datos obtenidos al estado
      })
      .catch((error) => {
        console.error("Error al obtener las campañas:", error);
      });
  }, []);

  return (
    <Container maxWidth="mb-3">
      <Typography variant="h4" gutterBottom>
        List Leads
      </Typography>
      <Button
        variant="contained"
        color="primary"
        onClick={() => navigate("/leads-create")} // Navegar a CampaignCreate
        style={{ marginBottom: "16px" }}
      >
        New Leads
      </Button>
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Name</TableCell>
              <TableCell>Email</TableCell>
              <TableCell>Phone Number</TableCell>
              <TableCell>Current Coverage</TableCell>
              <TableCell>Dependents</TableCell>
              <TableCell>health Status</TableCell>
              <TableCell>Income</TableCell>
              <TableCell>Interested</TableCell>
              <TableCell>State</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {leads.map((leads) => (
              <TableRow key={leads.id}>
                <TableCell>{leads.name}</TableCell>
                <TableCell>{leads.email}</TableCell>
                <TableCell>{leads.phone_number}</TableCell>
                <TableCell>{leads.current_coverage}</TableCell>
                <TableCell>{leads.dependents}</TableCell>
                <TableCell>{leads.health_status}</TableCell>
                <TableCell>{leads.income}</TableCell>
                <TableCell>{leads.interested_in}</TableCell>
                <TableCell>{leads.state}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Container>
  );
}

export default LeadList;
