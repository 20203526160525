import React, { useEffect, useState } from "react";
import { getCampaigns, deleteCampaign } from "../services/api";
import {
  Container,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  IconButton,
  Paper,
  Avatar,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete"; // Importa el icono de eliminar
import { useNavigate } from "react-router-dom"; // Para navegación
import ScheduleIcon from "@mui/icons-material/Schedule"; // Icono de programación

function CampaignList() {
  const [campaigns, setCampaigns] = useState([]);
  const [open, setOpen] = useState(false); // Estado para el diálogo
  const [selectedCampaignId, setSelectedCampaignId] = useState(null); // Guardar el ID de la campaña seleccionada
  const navigate = useNavigate(); // Hook para navegar entre rutas

  useEffect(() => {
    getCampaigns()
      .then((response) => {
        setCampaigns(response.data); // Asigna los datos obtenidos al estado
      })
      .catch((error) => {
        console.error("Error al obtener las campañas:", error);
      });
  }, []);

  // Abrir el cuadro de diálogo
  const handleClickOpen = (id) => {
    setSelectedCampaignId(id);
    setOpen(true);
  };

  // Cerrar el cuadro de diálogo
  const handleClose = () => {
    setOpen(false);
    setSelectedCampaignId(null);
  };

  const handleDelete = () => {
    deleteCampaign(selectedCampaignId)
      .then(() => {
        setCampaigns(
          campaigns.filter((campaign) => campaign.id !== selectedCampaignId)
        );
        handleClose();
      })
      .catch((error) => {
        console.error("Error al eliminar la campaña:", error);
      });
  };

  return (
    <Container maxWidth="mb-3">
      <Typography variant="h4" gutterBottom>
        List Campaigns
      </Typography>
      <Button
        variant="contained"
        color="primary"
        onClick={() => navigate("/campaign-create")} // Navegar a CampaignCreate
        style={{ marginBottom: "16px" }}
      >
        New Campaigns
      </Button>
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Avatar</TableCell>
              <TableCell>Name</TableCell>
              <TableCell>Description</TableCell>
              <TableCell colSpan={2}></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {campaigns.map((campaign) => (
              <TableRow key={campaign.id}>
                <TableCell>
                  <Avatar
                    alt={campaign.title}
                    src={campaign.image}
                    sx={{ width: 56, height: 56 }}
                    onError={(e) => {
                      e.target.src = "https://via.placeholder.com/56";
                    }}
                  />
                </TableCell>
                <TableCell>{campaign.title}`</TableCell>
                <TableCell>{campaign.description}</TableCell>
                <TableCell>
                  <IconButton
                    title="Schedule"
                    color="primary"
                    onClick={() =>
                      navigate(`/campaign-schedule/${campaign.id}`)
                    }
                  >
                    <ScheduleIcon />
                  </IconButton>
                </TableCell>
                <TableCell>
                  <Button
                    title="Delete" // Texto de ayuda
                    startIcon={<DeleteIcon />} // Icono de eliminar
                    onClick={() => handleClickOpen(campaign.id)}
                  ></Button>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      {/* Cuadro de diálogo de confirmación */}
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{"Confirm Deletion"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Are you sure you want to delete this campaign? This action cannot be
            undone.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Cancel
          </Button>
          <Button onClick={handleDelete} color="secondary" autoFocus>
            Confirm
          </Button>
        </DialogActions>
      </Dialog>
    </Container>
  );
}

export default CampaignList;
