import React from "react";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import { Link } from "react-router-dom";

function Navbar() {
  return (
    <AppBar position="static">
      <Toolbar>
        <Typography variant="h6" style={{ flexGrow: 1 }}>
          Marketing App
        </Typography>
        <Button color="inherit" component={Link} to="/dashboard">Dashboard</Button>
        <Button color="inherit" component={Link} to="/leads">Leads</Button>
        <Button color="inherit" component={Link} to="/campaigns">Campaigns</Button>
        <Button color="inherit" component={Link} to="/">Logout</Button>
      </Toolbar>
    </AppBar>
  );
}

export default Navbar;
