import React, { useEffect, useState } from "react";
import { getDashboardData } from "../services/api";

function Dashboard() {
  const [data, setData] = useState(null);

  useEffect(() => {
    getDashboardData()
      .then((response) => {
        setData(response.data);
      })
      .catch((error) => {
        console.error("Error al cargar datos del dashboard", error);
      });
  }, []);

  return (
    <div className="container mt-5">
      <h2>Dashboard</h2>
      {data ? (
        <div>
          {/* Renderizar los datos del dashboard aquí */}
          <p>Datos cargados: {JSON.stringify(data)}</p>
        </div>
      ) : (
        <p>Cargando datos...</p>
      )}
    </div>
  );
}

export default Dashboard;
