import React, { useState } from "react";
import { createCampaign } from "../services/api";
import {
  TextField,
  Button,
  Container,
  Typography,
  Snackbar,
  Alert,
} from "@mui/material";

function CampaignCreate() {
  const [title, setTitle] = useState("");
  const [descriptionimg, setDescriptionimg] = useState("");
  const [description, setDescription] = useState("");
  const [imgurl, setImgurl] = useState("");
  const [open, setOpen] = useState(false);
  const [message, setMessage] = useState("");
  const [severity, setSeverity] = useState("success"); // 'success' o 'error'

  const handleSubmit = async (e) => {
    e.preventDefault();
    // Aquí iría la lógica para enviar la campaña al backend
    try {
      const campaignData = {
        title,
        description: description,
        description_img: descriptionimg,
        image_url: imgurl,
      };
      const response = await createCampaign(campaignData);
      console.log("Campaña creada:", response);
      // Muestra el Snackbar de éxito
      setMessage("Campaign captured successfully.");
      setSeverity("success");
      setOpen(true);

      // Limpiar los campos del formulario
      setTitle("");
      setDescription("");
      setDescriptionimg("");
      setImgurl("");
    } catch (error) {
      console.error("Error al crear la campaña:", error);

      // Muestra el Snackbar de error
      setMessage("Error capturing Campaign.");
      setSeverity("error");
      setOpen(true);
    }
    console.log("Campaña creada:", {
      title,
      description,
      descriptionimg,
      imgurl,
    });
  };

  return (
    <Container maxWidth="sm">
      <Typography variant="h4" gutterBottom>
        Create Campaign
      </Typography>
      <form onSubmit={handleSubmit}>
        <TextField
          label="Campaign Name"
          variant="outlined"
          fullWidth
          margin="normal"
          value={title}
          onChange={(e) => setTitle(e.target.value)}
        />
        <TextField
          label="Campaign Text"
          variant="outlined"
          fullWidth
          margin="normal"
          multiline
          rows={4}
          value={description}
          onChange={(e) => setDescription(e.target.value)}
        />
        <TextField
          label="Description to create the image"
          variant="outlined"
          fullWidth
          margin="normal"
          multiline
          rows={4}
          value={descriptionimg}
          onChange={(e) => setDescriptionimg(e.target.value)}
        />
        <TextField
          label="Image URL"
          variant="outlined"
          fullWidth
          margin="normal"
          value={imgurl}
          onChange={(e) => setImgurl(e.target.value)}
        />
        <Button type="submit" variant="contained" color="primary">
          Create Campaign
        </Button>
      </form>
      <Snackbar
        open={open}
        autoHideDuration={6000}
        onClose={() => setOpen(false)}
      >
        <Alert
          onClose={() => setOpen(false)}
          severity={severity}
          sx={{ width: "100%" }}
        >
          {message}
        </Alert>
      </Snackbar>
    </Container>
  );
}

export default CampaignCreate;
