import axios from 'axios';

//const API_URL = 'http://127.0.0.1:8000/api'; // Ajusta esta URL a tu API
const API_URL = 'https://marketing.sysagencysphere.com/api'; 

// Nueva función para programar la campaña
export const scheduleCampaign = (campaignId, scheduledTime, intervalHours) => {
  return axios.post(`${API_URL}/schedule_campaign/`, {
    campaignId,
    scheduledTime,
    intervalHours,
  });
};

export const cancelCampaign = async (campaignId) => {
  const response = await axios.post(`${API_URL}/cancel_campaign/${campaignId}/`);
  return response.data;
};

export const login = (credentials) => {
  return axios.post(`${API_URL}/login/`, credentials);
};

export const createLead = (leadData) => {
  console.log("Sending lead data:", leadData);
  return axios.post(`${API_URL}/leads/`, leadData);
};

export const getLeads = () => {
  return axios.get(`${API_URL}/leads/`);
};

export const createCampaign = (campaignData) => {
  return axios.post(`${API_URL}/campaigns/`, campaignData);
};

export const getCampaigns = () => {
  return axios.get(`${API_URL}/campaigns/`);
};

export const deleteCampaign = (id) => {
  return axios.delete(`${API_URL}/campaigns/${id}/`);
};

export const getDashboardData = () => {
  return axios.get(`${API_URL}/dashboard/`);
};


